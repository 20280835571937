const content = {
  title: 'GPSC - ST Lookup Table',
  sections: [
    {
      content: [
        {
          type: 'md',
          content: 'Use the below table to see which Sequence Types are associated with each GPSC.'        
        }
      ]
    }
  ],
}

export default content