const content = {
  title: 'GPSC Lineages',
  sections: [
    {
      content: [
        {
          type: 'md',
          content: 'Each Global Pneumococcal Sequence Cluster (GPSC) is **an international genomic definition of a pneumococcal lineage** ([Gladstone & Lo et al 2019 Ebiomedicine](https://doi.org/10.1016/j.ebiom.2019.04.021) defined using the clustering software [PopPUNK](https://doi.org/10.1101/gr.241455.118). Using GPSCs, we can capture all variations across the entire pneumococcal genome, allowing us to monitor and understand the processes involved in vaccine escape ([Lo & Gladstone et al 2019 Lancet Infectious Diseases](https://doi.org/10.1016/S1473-3099(19)30297-X)).'        
        },
        {
          type: 'md',
          content: '- You can assign GPSCs using Pathogenwatch ([tutorial video](https://www.youtube.com/watch?v=Q8bDuZZ3hXg)) or command line ([step-by-step instruction](../training#gpsc-assignment)).',
        },
        {
          type: 'md',
          content: "- You can explore the phylogenies of common GPSCs in Microreact, and their recombination pattern in Phandango by hovering over the cluster (each cluster represents a GPSC) in the following grid",
        },
      ]
    }
  ],
  gpscGrid: [
    {
      gpsc: '1',
      url: 'https://microreact.org/project/gpsGPSC1',
      sampleCount: 504,
      st: [202, 236, 237, 257, 271, 320, 420, 651, 652, 925, 1396, 1412, 1421, 1428, 1451, 1464, 1937, 2514, 2706, 2834, 2920, 2998, 3259, 3791, 4251, 4768, 5053, 5459, 6141, 6844, 7132, 7915, 8678, 9356, 9744, 9906, 9933, 10143, 10223, 10683, 10731, 10822, 10825, 10829, 11101, 11116, 11129, 11326, 11396, 11696, 12134, 12136, 12185, 12186, 12189, 12228, 12229, 12308, 12526, 12533, 12534, 12536, 12537, 12543, 12560, 12586, 12591, 12617, 12686, 12706, 12709, 12712, 12728, 12730, 12822, 12825, 12827, 13934, 13948],
      serotype: ['3', '6C', '14', '19A', '19F', '23F'],
    }, 
    {
      gpsc: '2',
      url: 'https://microreact.org/project/gpsGPSC2',
      sampleCount: 782,
      st: [217, 303, 611, 612, 615, 618, 2084, 2296, 3081, 3575, 3579, 3581, 5002, 5012, 5044, 5191, 5802, 6983, 8158, 8314, 9067, 9231, 9529, 10649, 10996, 11046, 11104, 11740, 11743, 11745, 12150, 12165, 12333, 12470, 12546, 12556, 12739, 12781],
      serotype: ['1'],
    }, 
    {
      gpsc: '3',
      url: 'https://microreact.org/project/gpsGPSC3',
      sampleCount: 358,
      st: [53, 60, 62, 100, 445, 496, 662, 673, 717, 1012, 1050, 2223, 2420, 2705, 3000, 3276, 3308, 3778, 3847, 6360, 7507, 8989, 9029, 9750, 10068, 10145, 10711, 10857, 10920, 11074, 11124, 11686, 12148, 12542, 12547, 12574, 12715, 12735, 12754, 12755, 12823, 13888, 13889, 13947],
      serotype: ['3', '8', '31', '11A', '15A', '18C', '22F', '23F', '33A', '33F'],
    }, 
    {
      gpsc: '4',
      url: 'https://microreact.org/project/gpsGPSC4',
      sampleCount: 203,
      st: [199, 200, 201, 411, 416, 419, 645, 667, 668, 876, 1200, 1673, 1756, 1757, 1936, 2081, 2154, 2220, 2269, 2273, 2344, 2540, 3934, 8290, 9763, 12376, 12711, 12729, 12731],
      serotype: ['14', '15B/C', '19A', '19B', '19F'],
    }, 
    {
      gpsc: '5',
      url: 'https://microreact.org/project/gpsGPSC5',
      sampleCount: 453,
      st: [172, 277, 337, 338, 361, 387, 988, 1131, 1150, 1373, 1447, 1526, 1535, 2218, 2345, 2372, 2777, 2904, 3163, 4028, 4104, 4293, 5242, 5547, 5814, 6140, 6849, 6980, 7096, 7480, 8197, 9488, 9913, 9914, 10149, 10150, 10542, 10599, 10603, 10650, 10652, 10724, 10732, 10744, 10767, 10809, 10817, 10889, 11119, 11704, 11715, 11716, 11717, 11899, 12126, 12164, 12327, 12329, 12352, 12525, 12541, 12580, 12593, 12594, 12672, 12742, 12761, 12835, 13873, 13916, 13919],
      serotype: ['3', '6A', '6C', '7B', '7C', '15B/C', '17F', '19A', '19F', '23A', '23B', '23F', '29', '35B', '35D'],
    }, 
    {
      gpsc: '6',
      url: 'https://microreact.org/project/gpsGPSC6',
      sampleCount: 393,
      st: [44, 143, 156, 162, 166, 379, 466, 608, 643, 671, 933, 1206, 1227, 1269, 1556, 1569, 1836, 1857, 2128, 2335, 2722, 2733, 3811, 4026, 7427, 7432, 7456, 8398, 8488, 9910, 9912, 10174, 10449, 10834, 11921, 12505, 12506, 12507, 12508, 12554, 12704, 12758, 12830, 12834, 12839, 13932, 13933],
      serotype: ['9V', '11A', '14', '15A', '15B/C', '19A', '19F', '23A', '23B', '23F', '35B'],
    }, 
    {
      gpsc: '7',
      url: 'https://microreact.org/project/gpsGPSC7',
      sampleCount: 166,
      st: [33, 36, 37, 42, 264, 311, 355, 436, 438, 439, 507, 628, 629, 692, 945, 992, 1011, 1389, 1839, 1847, 2319, 5473, 7318, 7336, 7461, 8802, 9452, 9749, 9751, 10727, 11063, 12122, 12139, 12251, 12733, 12762, 12826, 13874],
      serotype: ['23A', '23B', '23F'],
    }, 
    {
      gpsc: '8',
      url: 'https://microreact.org/project/gpsGPSC8',
      sampleCount: 390,
      st: [245, 289, 3339, 3404, 4219, 5659, 7050, 7222, 9935, 10999],
      serotype: ['5'],
    }, 
    {
      gpsc: '9',
      url: 'https://microreact.org/project/gpsGPSC9',
      sampleCount: 277,
      st: [63, 782, 861, 1191, 2100, 2414, 2447, 2613, 2675, 2678, 3310, 3816, 4532, 4652, 4902, 4907, 5004, 5187, 5285, 5435, 6043, 7061, 7551, 7552, 7645, 8324, 9352, 9468, 9939, 10608, 10737, 10758, 10894, 11018, 11022, 11029, 11030, 11041, 11399, 11687, 12260, 12334, 12360, 12364, 12555, 12559, 12576, 12723, 12738, 12787, 13952],
      serotype: ['3', '14', '15A', '19A', '19F', '23B', '23F', '33F', 'NT'],
    }, 
    {
      gpsc: '10',
      url: 'https://microreact.org/project/gpsGPSC10',
      sampleCount: 287,
      st: [230, 276, 319, 700, 1047, 1087, 1553, 2013, 2037, 2307, 2355, 2707, 3135, 3772, 3936, 4253, 4677, 5033, 5034, 5567, 6227, 6353, 6741, 8374, 8509, 8812, 8857, 9540, 10696, 10745, 10811, 10879, 10917, 10921, 11021, 11052, 11100, 11106, 11748, 11749, 12473, 12474, 12498, 12499, 12684, 12790, 12794, 12819, 13877, 13880, 13885, 13925],
      serotype: ['3', '7B', '10A', '14', '17F', '19A', '19F', '23A', '23F', '24'],
    }, 
    {
      gpsc: '11',
      url: 'https://microreact.org/project/gpsGPSC11',
      sampleCount: 228,
      st: [193, 410, 889, 1228, 1262, 1358, 2575, 2927, 3065, 3689, 4893, 4990, 5281, 5848, 6031, 6237, 6444, 7068, 7435, 7436, 7600, 7619, 7723, 9516, 9517, 9692, 9975, 10065, 10227, 10721, 10774, 10783, 10854, 10885, 10951, 11003, 11023, 11031, 11035, 11059, 11730, 11760, 11920, 12138, 12517, 12518, 12602, 12838, 13871],
      serotype: ['3', '6A', '9N', '11A', '14', '15A', '15B/C', '17F', '18A', '18B', '18C', '19A', '19F', '21', '33C'],
    }, 
    {
      gpsc: '12',
      url: 'https://microreact.org/project/gpsGPSC12',
      sampleCount: 205,
      st: [180, 505, 2049, 3798, 4655, 4735, 6012, 6013, 9060, 10179, 10768, 11873, 12206, 12319, 12734],
      serotype: ['3'],
    }, 
    {
      gpsc: '13',
      url: 'https://microreact.org/project/gpsGPSC13',
      sampleCount: 351,
      st: [471, 473, 497, 660, 690, 1096, 1717, 1876, 2285, 2295, 3822, 4087, 4957, 5073, 5470, 5501, 5611, 5679, 6372, 6373, 6382, 6390, 7073, 7310, 7313, 7423, 7736, 7744, 8003, 8696, 9506, 9532, 9533, 9575, 9915, 9920, 9923, 10630, 10664, 10671, 10704, 10713, 10717, 10718, 10736, 10748, 10750, 10773, 10844, 10847, 10888, 10898, 10915, 10934, 10948, 10955, 11042, 11099, 11121, 11123, 11125, 12225, 12227, 12338, 12493, 12503, 12516, 12529, 12545, 12548, 12561, 12568, 12570, 12605, 13901, 13902, 13922],
      serotype: ['6A', '6B', '6C', '6D', '23F', '29', '35B'],
    }, 
    {
      gpsc: '14',
      url: 'https://microreact.org/project/gpsGPSC14',
      sampleCount: 338,
      st: [240, 242, 805, 880, 1444, 1445, 2059, 2651, 3140, 5653, 6279, 6285, 6305, 6344, 8299, 8327, 8334, 8335, 8337, 8716, 8741, 9911, 9932, 10571, 10604, 10653, 10735, 10738, 10791, 10796, 10816, 10818, 10859, 10936, 11056, 11069, 11088, 11092, 12147, 12347, 12405, 12562, 12612],
      serotype: ['6B', '11A', '14', '19A', '19F', '23F'],
    }, 
    {
      gpsc: '15',
      url: 'https://microreact.org/project/gpsGPSC15',
      sampleCount: 164,
      st: [191, 1589, 5782, 10162, 10765, 11728],
      serotype: ['7F', '19F'],
    }, 
    {
      gpsc: '16',
      url: 'https://microreact.org/project/gpsGPSC16',
      sampleCount: 231,
      st: [66, 67, 70, 71, 72, 73, 81, 83, 282, 517, 632, 737, 1591, 2216, 2346, 3800, 4913, 5593, 6216, 7452, 8809, 9257, 9747, 9904, 9905, 10869, 11683, 12173, 12175, 12483, 12487, 12703, 12837, 12840, 13878, 13939],
      serotype: ['6A', '7C', '9N', '13', '14', '24', '15A', '15B/C', '18C', '19A', '19F', '23F', '40'],
    }, 
    {
      gpsc: '17',
      url: 'https://microreact.org/project/gpsGPSC17',
      sampleCount: 320,
      st: [2057, 2062, 4869, 4872, 4873, 6121, 6255, 6258, 6262, 6266, 6268, 7056, 7057, 7072, 7895, 7897, 7899, 7903, 8303, 8307, 9457, 9900, 10628, 10634, 10690, 10693, 10741, 10756, 10790, 10843, 10870, 10952, 11065, 11076, 11085, 11114, 11122, 11677, 11678, 12152, 12154, 12226, 12521, 12564],
      serotype: ['9L', '19A', '19F'],
    }, 
    {
      gpsc: '18',
      url: 'https://microreact.org/project/gpsGPSC18',
      sampleCount: 228,
      st: [9, 13, 15, 16, 25, 423, 721, 733, 984, 1201, 1492, 1797, 2258, 2652, 3162, 3907, 4191, 5468, 6144, 6809, 7448, 7485, 8006, 8753, 9054, 9898, 9899, 10747, 10814, 11675, 12232, 12552, 12699, 12836, 13867, 13931],
      serotype: ['7C', '14', '16F', '19A', '19F'],
    }, 
    {
      gpsc: '19',
      url: 'https://microreact.org/project/gpsGPSC19',
      sampleCount: 118,
      st: [433, 1337, 1955, 4334, 4553, 7314, 9456, 10163, 10164, 10226, 12708, 12710],
      serotype: ['22F', '23B', '31', '42'],
    }, 
    {
      gpsc: '20',
      url: 'https://microreact.org/project/gpsGPSC20',
      sampleCount: 92,
      st: [802, 6120, 6856, 9530, 9535, 10644, 10887, 12355, 12520, 12724, 12842, 12845],
      serotype: ['23F'],
    }, 
    {
      gpsc: '21',
      url: 'https://microreact.org/project/gpsGPSC21',
      sampleCount: 242,
      st: [347, 2715, 5179, 6095, 6277, 7142, 9547, 9548, 9927, 9930, 10536, 10553, 10572, 10598, 10619, 10622, 10632, 10655, 10709, 10752, 10784, 10794, 10798, 10842, 10896, 10932, 10933, 10935, 10941, 10943, 10949, 10974, 10988, 11086, 11115, 11747, 12130, 12146, 12157, 12183, 12332, 12339, 12585, 12598, 12601, 12609, 12700, 12740],
      serotype: ['14', '19A', '19F'],
    }, 
    {
      gpsc: '22',
      url: 'https://microreact.org/project/gpsGPSC22',
      sampleCount: 240,
      st: [840, 2052, 4984, 5408, 5722, 5902, 8817, 9518, 10545, 10547, 10544, 10597, 10602, 10654, 10677, 10734, 10753, 10761, 10856, 10861, 10874, 10906, 10968, 10991, 11060, 11078, 11083, 11108, 11110, 11724, 11725, 11726, 11727, 11732, 11733, 11778, 12179, 12293, 12294, 12297, 12309, 12313, 12315, 12324, 12331, 12492, 12540, 12565, 12575, 12583, 12616],
      serotype: ['6A', '9V', '11A', '15A', '19A', '19F', '20A', '35A'],
    }, 
    {
      gpsc: '23',
      url: 'https://microreact.org/project/gpsGPSC23',
      sampleCount: 180,
      st: [90, 93, 94, 95, 145, 146, 273, 384, 640, 641, 1121, 1129, 1394, 1509, 1518, 1624, 1661, 1792, 2156, 2456, 3051, 4039, 4236, 5628, 6410, 6457, 7416, 7417, 7418, 7460, 8052, 10904, 10912, 10965, 11237, 11305, 11700, 12495, 12721, 12732, 12756, 12757, 13905, 13942],
      serotype: ['6A', '6B', '19A'],
    }, 
    {
      gpsc: '24',
      url: 'https://microreact.org/project/gpsGPSC24',
      sampleCount: 86,
      st: [138, 176, 469, 639, 2459, 4241, 4598, 6389, 6774, 7430, 10723, 10725, 10742, 12172, 12358, 12760, 12763],
      serotype: ['6A', '6B', '6C', '19A'],
    }, 
    {
      gpsc: '25',
      url: 'https://microreact.org/project/gpsGPSC25',
      sampleCount: 112,
      st: [4209, 7522, 8687, 11019, 12615],
      serotype: ['15B/C'],
    }, 
    {
      gpsc: '26',
      url: 'https://microreact.org/project/gpsGPSC26',
      sampleCount: 195,
      st: [989, 3334, 3377, 4187, 5026, 6058, 7110, 8798, 9544, 11027, 11075, 11105, 11780, 12342, 12784, 12813],
      serotype: ['9V', '12A', '12F', '40', '46'],
    }, 
    {
      gpsc: '27',
      url: 'https://microreact.org/project/gpsGPSC27',
      sampleCount: 118,
      st: [205, 206, 244, 246, 247, 695, 853, 899, 2365, 5660, 9557, 9561, 9934, 10612, 12714],
      serotype: ['4', '14', '19A', '19F'],
    },
    {
      gpsc: '30',
      url: 'https://microreact.org/project/gpsGPSC30',
      sampleCount: 120,
      st: [3735, 4084, 4900, 5521, 7055, 7062, 7533, 8151, 8157, 8714, 8774, 9174, 9475, 10177, 10555, 10586, 10810, 10892, 10910, 10997, 11050, 11690, 12133, 12180, 12182, 12245, 12491, 12600],
      serotype: ['6A', '6B', '9V', '10A', '10B', '10F', '19F', '33D', '35B', '35F'],
    }, 
    {
      gpsc: '31',
      url: 'https://microreact.org/project/gpsGPSC31',
      sampleCount: 111,
      st: [227, 304, 306, 4752, 5316, 5784, 13923],
      serotype: ['1'],
    }, 
    {
      gpsc: '32',
      url: 'https://microreact.org/project/gpsGPSC32',
      sampleCount: 124,
      st: [218, 220, 405, 1176, 2178, 2233, 2331, 3544, 4666, 5455, 10663, 11901],
      serotype: ['7F', '8', '9N', '12F'],
    }, 
    {
      gpsc: '33',
      url: 'https://microreact.org/project/gpsGPSC33',
      sampleCount: 170,
      st: [4088, 5072, 7888, 9568, 10543, 10548, 10592, 10635, 10651, 10667, 10670, 10800, 10853, 10862, 10950, 10981, 11055, 11072, 11080, 11089, 11090, 11111, 11113, 11770, 11771, 11772, 12137, 12143, 12550, 12551, 12592, 12743],
      serotype: ['16F', '19A', '19F'],
    }, 
    {
      gpsc: '34',
      url: 'https://microreact.org/project/gpsGPSC34',
      sampleCount: 151,
      st: [1778, 3583, 4038, 4832, 5641, 7067, 7319, 9546, 10544, 10615, 10662, 10679, 10680, 10697, 10710, 10865, 10940, 10960, 10963, 10982, 10987, 11064, 11746, 12588, 12597, 12719, 12805],
      serotype: ['15A', '22F', '23F', '34'],
    },
    {
      gpsc: '37',
      url: 'https://microreact.org/project/gpsGPSC37',
      sampleCount: 148,
      st: [2421, 2909, 4929, 6290, 6393, 6527, 7084, 7306, 8379, 8391, 8771, 9902, 10659, 10707, 10743, 10751, 10760, 10763, 10770, 10775, 10788, 10815, 10959, 10976, 11067, 11695, 12557, 12607],
      serotype: ['6B', '23F'],
    }, 
    {
      gpsc: '38',
      url: 'https://microreact.org/project/gpsGPSC38',
      sampleCount: 92,
      st: [310, 393, 755, 5475, 6355, 9325, 11082, 12764, 13935, 13945],
      serotype: ['38'],
    }, 
    {
      gpsc: '39',
      url: 'https://microreact.org/project/gpsGPSC39',
      sampleCount: 67,
      st: [124, 129, 656, 2215, 9917, 10831],
      serotype: ['14'],
    }, 
    {
      gpsc: '40',
      url: 'https://microreact.org/project/gpsGPSC40',
      sampleCount: 112,
      st: [910, 5266, 7054, 9459, 9470, 9522, 9903, 10600, 10605, 10626, 10636, 10638, 10939, 10983, 11057, 11697, 12158, 12162, 12252, 12613, 12844],
      serotype: ['6B', '10A', '11A', '15A', '15B/C', '17F', '18C', '22A', '23F'],
    }, 
    {
      gpsc: '41',
      url: 'https://microreact.org/project/gpsGPSC41',
      sampleCount: 125,
      st: [1094, 4941, 7251, 8690, 8766, 9909, 10656, 10691, 10708, 10729, 10749, 10785, 10820, 11091, 11117, 12538, 12566, 12569],
      serotype: ['6A', '6B', '23B'],
    },
    {
      gpsc: '43',
      url: 'https://microreact.org/project/gpsGPSC43',
      sampleCount: 111,
      st: [239, 280, 1263, 2039, 3214, 4128, 4661, 4745, 5492, 5719, 6011, 6053, 7664, 10901, 11739, 11758, 12304, 12404, 13912, 13937],
      serotype: ['3', '9V', '11A', '18C', '18F', '19A', '20B', '35A'],
    },
    {
      gpsc: '47',
      url: 'https://microreact.org/project/gpsGPSC47',
      sampleCount: 101,
      st: [149, 315, 386, 457, 2040, 3324, 4220, 4378, 5060, 6046, 6361, 6984, 8599, 9600, 10672, 10681, 10966, 11315, 13890],
      serotype: ['6A', '6B', '6C', '9A', '9V'],
    }, {
      gpsc: '48',
      url: 'https://microreact.org/project/gpsGPSC48',
      sampleCount: 83,
      st: [346, 1203, 1835, 3201, 3557, 7052, 12127, 12614, 12816],
      serotype: ['15B/C', '19F'],
    },
    {
      gpsc: '50',
      url: 'https://microreact.org/project/gpsGPSC50',
      sampleCount: 61,
      st: [113, 123, 133, 638, 1073, 1515, 1516, 1711, 1844, 3060, 9511, 9512, 9513, 9565, 9921, 10833, 11900],
      serotype: ['13', '17F', '18B', '18C'],
    }, 
    {
      gpsc: '51',
      url: 'https://microreact.org/project/gpsGPSC51',
      sampleCount: 94,
      st: [458, 7545, 11128, 11332, 12142, 12590, 12606],
      serotype: ['3'],
    }, 
    {
      gpsc: '52',
      url: 'https://microreact.org/project/gpsGPSC52',
      sampleCount: 100,
      st: [5647, 8713, 10595, 10702, 10849, 10937, 11757, 12581, 12595],
      serotype: ['6B', '13'],
    }, 
    {
      gpsc: '53',
      url: 'https://microreact.org/project/gpsGPSC53',
      sampleCount: 99,
      st: [847, 5262, 11714, 12191, 12292],
      serotype: ['19A'],
    },
    {
      gpsc: '54',
      url: 'https://microreact.org/project/gpsGPSC54',
      sampleCount: 97,
      st: [706, 3454, 4881, 5778, 6314, 6317, 6530, 7058, 7059, 7889, 8763, 9473, 10688, 10789, 10797, 10876, 10895, 10944, 11680, 12326, 12532, 12558, 12567],
      serotype: ['9V', '23F', '28F'],
    },
    {
      gpsc: '55',
      url: 'https://microreact.org/project/gpsGPSC55',
      sampleCount: 90,
      st: [3524, 3774, 3776, 13950],
      serotype: ['12F'],
    },
    {
      gpsc: '56',
      url: 'https://microreact.org/project/gpsGPSC56',
      sampleCount: 92,
      st: [2416, 5027, 5068, 7048, 7141, 8672, 9531, 10675, 10792, 11767, 11768, 11769, 11915, 12151, 12820, 12951],
      serotype: ['7F', '12F', '18C'],
    }, 
    {
      gpsc: '57',
      url: 'https://microreact.org/project/gpsGPSC57',
      sampleCount: 56,
      st: [444, 568, 1111, 1766, 1994, 3358, 3548, 6029, 8289, 10479, 10821, 12144, 12582, 12682],
      serotype: ['31'],
    },
    {
      gpsc: '58',
      url: 'https://microreact.org/project/gpsGPSC58',
      sampleCount: 88,
      st: [4036, 5516, 5715, 5716, 5734, 10546, 10613, 11689, 11691, 11762],
      serotype: ['6A', '6B', '19A'],
    }, 
    {
      gpsc: '61',
      url: 'https://microreact.org/project/gpsGPSC61',
      sampleCount: 71,
      st: [214, 698, 1794, 4160, 5469, 5474, 6403, 8829, 8835, 9526, 9537, 9926, 10593, 10668, 10689, 10715, 10781, 10946, 11097, 11737, 11738, 11744, 12579, 13868],
      serotype: ['17F', '18A', '20A', '20B', '22F', '35A', '35C'],
    }, 
    {
      gpsc: '62',
      url: 'https://microreact.org/project/gpsGPSC62',
      sampleCount: 79,
      st: [913, 914, 1736, 1737, 4940, 6547, 9489, 10801],
      serotype: ['6A', '6B'],
    },
    {
      gpsc: '65',
      url: 'https://microreact.org/project/gpsGPSC65',
      sampleCount: 73,
      st: [2053],
      serotype: ['13', '19A'],
    },
    {
      gpsc: '67',
      url: 'https://microreact.org/project/gpsGPSC67',
      sampleCount: 61,
      st: [1233, 1381, 4350, 6145, 6239, 8316, 10779, 10994, 12544, 12596],
      serotype: ['18B', '18C', '21'],
    }, 
    {
      gpsc: '68',
      url: 'https://microreact.org/project/gpsGPSC68',
      sampleCount: 66,
      st: [102, 542, 1016, 2192, 6236, 6245, 7262, 8317, 8681, 9280, 9484, 10746, 11096, 11698, 11699, 12141, 12584],
      serotype: ['17F', '18B', '18C'],
    },
    {
      gpsc: '70',
      url: 'https://microreact.org/project/gpsGPSC70',
      sampleCount: 32,
      st: [1221, 7026, 13938, 13944, 13954],
      serotype: ['4'],
    }, 
    {
      gpsc: '72',
      url: 'https://microreact.org/project/gpsGPSC72',
      sampleCount: 57,
      st: [198, 3329, 7425, 7426, 10777, 11721, 12689, 12811, 13881],
      serotype: ['35B', '35D'],
    },
    {
      gpsc: '76',
      url: 'https://microreact.org/project/gpsGPSC76',
      sampleCount: 37,
      st: [76, 490, 681, 793, 1092, 3403, 4363, 4724, 6808, 10913, 11693],
      serotype: ['6A', '6B', '6C', '19F'],
    },
    {
      gpsc: '77',
      url: 'https://microreact.org/project/gpsGPSC77',
      sampleCount: 56,
      st: [185, 7307, 7738, 10661, 10686, 10720, 10726, 10845, 10846, 10871, 11719, 12528],
      serotype: ['6A', '6B'],
    },
    {
      gpsc: '78',
      url: 'https://microreact.org/project/gpsGPSC78',
      sampleCount: 55,
      st: [5511, 5706, 9931, 12291],
      serotype: ['23B'],
    }, 
    {
      gpsc: '79',
      url: 'https://microreact.org/project/gpsGPSC79',
      sampleCount: 52,
      st: [5410, 7071, 7370, 7376, 12539, 12571],
      serotype: ['4', '14', '19A'],
    }, 
    {
      gpsc: '80',
      url: 'https://microreact.org/project/gpsGPSC80',
      sampleCount: 50,
      st: [917, 10594, 10618, 10629, 10903, 11711, 11712, 12312, 12749],
      serotype: ['13', '15A', '16F', '19A', '19F', '7C'],
    }, 
    {
      gpsc: '81',
      url: 'https://microreact.org/project/gpsGPSC81',
      sampleCount: 38,
      st: [344, 2315, 4047, 7611, 9809, 10241, 10993, 11084, 11093, 11731, 11878, 12720],
      serotype: ['NT'],
    },
    {
      gpsc: '83',
      url: 'https://microreact.org/project/bvmKoXGt4TJJ4wnPxuJWok-gpsc83',
      sampleCount: 55,
      st: [232, 260, 378, 1220, 1377, 6014, 7369, 11931],
      serotype: ['3'],
    }, 
    {
      gpsc: '90',
      url: 'https://microreact.org/project/gpsGPSC90',
      sampleCount: 39,
      st: [7860, 8326, 8328, 8332, 8660, 9928, 10558, 10641, 10648, 10678, 10703, 10714, 10827, 11070, 11109, 11118, 11681, 12145, 12522],
      serotype: ['14', '15B/C', '19A', '19F'],
    }, 
    {
      gpsc: '91',
      url: 'https://microreact.org/project/gpsGPSC91',
      sampleCount: 27,
      st: [373, 5097, 8958, 9574, 10992, 11012],
      serotype: ['24', '40', '19B', '23F', '35B', '35D'],
    }, 
    {
      gpsc: '93',
      url: 'https://microreact.org/project/gpsGPSC93',
      sampleCount: 38,
      st: [4956, 8838, 10639, 11126, 11713],
      serotype: ['7C'],
    }, 
    {
      gpsc: '94',
      url: 'https://microreact.org/project/gpsGPSC94',
      sampleCount: 37,
      st: [2174, 6857, 9509, 10607, 10905, 11718, 12238],
      serotype: ['10A', '19A', '19F', '23F'],
    }, 
    {
      gpsc: '97',
      url: 'https://microreact.org/project/gpsGPSC97',
      sampleCount: 23,
      st: [376, 1339, 2268, 2270, 8207, 10159, 10178],
      serotype: ['6A', '19A'],
    }, 
    {
      gpsc: '103',
      url: 'https://microreact.org/project/gpsGPSC103',
      sampleCount: 32,
      st: [3407, 10778, 10805, 10908, 11764],
      serotype: ['14', '16F', '19F'],
    }, 
    {
      gpsc: '105',
      url: 'https://microreact.org/project/gpsGPSC105',
      sampleCount: 30,
      st: [5449, 5623, 5625, 5626, 6148, 6348, 7433, 7457, 8008],
      serotype: ['6A', '6B', '6D'],
    }, 
    {
      gpsc: '108',
      url: 'https://microreact.org/project/gpsGPSC108',
      sampleCount: 29,
      st: [915, 9487, 9938],
      serotype: ['14'],
    }, 
    {
      gpsc: '115',
      url: 'https://microreact.org/project/gpsGPSC115',
      sampleCount: 26,
      st: [],
      serotype: ['6B'],
    }, 
    {
      gpsc: '117',
      url: 'https://microreact.org/project/gpsGPSC117',
      sampleCount: 26,
      st: [6103, 11755, 11756, 12266],
      serotype: ['38'],
    }, 
    {
      gpsc: '131',
      url: 'https://microreact.org/project/gpsGPSC131',
      sampleCount: 22,
      st: [766, 3669, 8495, 13869, 13875],
      serotype: ['15B/C'],
    }
  ]
}

export default content