const content = {
  title: 'Serotypes',
  sections: [
    {
      content: [
        {
          type: 'md',
          content: 'Use the below table to see all the known serotypes and their associated capsular genes.'        
        }
      ]
    }
  ],
}

export default content